@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --green: #258635;
}

@font-face {
    font-family: 'Gilroy Extra-Bold';
    src: url('../fonts/Gilroy-FREE/Gilroy-FREE/Gilroy-ExtraBold.otf');
}

@font-face {
    font-family: 'Gilroy Bold';
    src: url('../fonts/Gilroy-FREE/Gilroy-FREE/Gilroy-Bold.ttf');
}

@font-face {
    font-family: 'Gilroy Medium';
    src: url('../fonts/Gilroy-FREE/Gilroy-FREE/Gilroy-Medium.ttf');
}

@font-face {
    font-family: 'Gilroy Light';
    src: url('../fonts/Gilroy-FREE/Gilroy-FREE/Gilroy-Light.otf');
}

* {
    margin: 0;
    padding: 0;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    /* background: #FFFFFF; */
}

body,
html {
    /* font-size: 10px; */
    color: #111;
    max-width: 1600px;
    margin: 0px auto;
    display: block;
    background: #FFFFFF;
    font-family: 'Gilroy Medium';
    font-family: "Work Sans", serif;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Poppins', sans-serif;
    font-family: 'Gilroy Bold';
    font-family: "Plus Jakarta Sans", serif;
    font-weight: 600;
    font-weight: normal;
}

.contain {
    padding: 0 2%;
}

.index .contain {
    padding: 0px 3%;
}

.container {
    padding: 0 6%;
}

.space_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.justify_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

/* .container {
    width: 80%;
    margin-left: 10%;
} */

.bg_green {
    background: var(--green);
    color: #fff;
    font-weight: 500;
    border-radius: 50px;
    padding: 12px 35px;
    border: 1px solid var(--green);
    font-size: 1.5rem;
}

.pay_bg_green {
    background: var(--green);
    color: #fff;
    font-weight: 500;
    border-radius: 4px;
    padding: 12px 35px;
    border: 1px solid var(--green);
    font-size: 1.5rem;
}

.pay_bg_blue {
    background: #192C69;
    color: #fff;
    font-weight: 500;
    border-radius: 4px;
    padding: 12px 35px;
    border: 1px solid #192C69;
    font-size: 1.5rem;
}

.bg_green_long {
    background: var(--green);
    color: #fff;
    font-weight: 500;
    width: 100%;
    border-radius: 50px;
    padding: 12px 35px;
    border: 1px solid var(--green);
    font-size: 1.5rem;
}

.bg_border_green.no_border,
.bg_green_long.no_border {
    padding: 10px 35px;
    border-radius: 3px;
    width: 100%;
}

.bg_green.no_border {
    width: max-content;
    padding: 10px 35px;
    border-radius: 4px;
}

.bg_white {
    background: #fff;
    color: var(--green);
    font-weight: 500;
    border-radius: 50px;
    padding: 12px 35px;
    border: 1px solid #fff;
    font-size: 1.5rem;
}

.default-btn {
    background: transparent;
    border: 2px solid #E8E8E8;
    color: #111;
    padding: 10px 25px;
    border-radius: 4px;
}

.referal-modal .ant-modal-close {
    display: block;
}

.bg_blank {
    background: #e8e8e8;
    color: var(--green);
    font-weight: 500;
    border-radius: 50px;
    padding: 10px 35px;
    border: 1px solid #e8e8e8;
    font-size: 1.5rem;
    width: max-content;
}

.bg_side_green {
    background: #008060;
    color: #fff;
    padding: 8px 35px;
}

.bg_border_green,
.bg_side_green {
    border-radius: 50px;
    border: 2px solid #008060;
    font-weight: 500;
    font-size: 1.4rem;
}

.bg_border_green {
    background: transparent;
    color: #008060;
    padding: 9px 25px;
    font-size: 1.4rem;
}

button.default {
    background: transparent;
    border: none;
    padding: 0 25px;
    outline: none;
    font-size: 1.4rem;
}

.carousel-with-margin .owl-stage {
    /* margin-left: -20px !important; */
}

.carousel-with-margin .owl-theme .owl-nav.disabled+.owl-dots {
    margin: 0 !important;
    display: none;
}

.banner .ant-modal-body {
    padding: 0px !important;
}

.margin-seven {
    margin-top: 7%;
}

.props,
.banner {
    margin-top: 3%;
    clear: both;
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.mt-5 {
    margin-top: 5% !important;
}

.mt-3 {
    margin-top: 3% !important;
}

.new_flex,
.top_header .flex-2 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
}

.new_flex .flex_div {
    -webkit-flex: 1 1;
    flex: 1 1;
}

.center-text {
    text-align: center;
}

.center-text p {
    margin-bottom: 0px !important;
}

.center-text svg {
    width: 15%;
    height: auto;
    margin-bottom: 20px;
}

.form-flex-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    grid-gap: 5px;
}

label {
    font-weight: 400;
    font-size: 1.3rem;
    margin-bottom: 0%;
    color: #000;
}

.grid_flex,
.grid-flex-bias {
    display: flex;
    justify-content: space-between;
}

.form-error,
.error-message {
    color: #C8182F;
    font-size: 1.3rem;
}

.ant-select {
    width: 100% !important;
}

.slick-slide {
    padding-right: 5px;
}

.grid_flex.no_space {
    justify-content: unset;
}

.grid_2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
}

.grid_3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    grid-gap: 20px;
}

.grid_4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    grid-gap: 10px;
}

.grid_6 {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 10px;
}

.grid_2 img,
.grid_3 img,
.grid_4 img {
    width: 100%;
    height: 100%;
}

/* nav */
.mini_nav {
    background: #171717;
    padding: 8px 0px;
}

.mini_nav .div_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mini_nav .div_flex p,
.mini_nav .div_flex a {
    margin: 0px;
    color: #fff;
    font-size: 1.2rem;
}

.mini_nav .div_flex .wall_bal {
    /* display: none; */
    display: flex;
    align-items: center;
    gap: 5px;
    margin-left: auto !important;
}

.mini_nav .div_flex ul li a {
    color: #fff;
    font-size: 1.2rem;
}

.mini_nav .div_flex ul {
    margin: 0px;
}

.mini_nav .div_flex ul li {
    display: inline-block;
    padding-right: 25px;
}

.navigation {
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    /* padding: 10px 2%; */
    background: #fff;
    position: relative;
    /* box-shadow: 0 0 5px 2px rgba(0, 0, 0, .2); */
}

.navigation.fixed {
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .2);
}

.navigation.adjustPadding {
    padding: 20px 1.5%;
}

.navigation.no-shadow {
    position: relative;
    box-shadow: none;
    border-bottom: 1px solid #ECEDF1;
}

.navigation.fixed {
    position: fixed !important;
    top: 0%;
    width: 100%;
    background: #fff;
    z-index: 10;
    box-shadow: 0 0 3px rgba(0, 0, 0, .6);
}

.navigation.profile.shop {
    position: relative;
    border-bottom: 1px solid #ecedf1;
    box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, .2);
    z-index: 10;
}

/* .logo_bar {
    display: flex;
    align-items: center;
    font-weight: 500;
} */

.navigation ul {
    padding: 0%;
    margin: 0%;
}

.navigation ul li {
    /* display: inline-block; */
    padding: 0 15px;
    cursor: pointer;
}

.navigation ul li a {
    /* font-size: 1.4rem; */
    color: #202c2c;
    font-weight: 500;
}

.navigation img.logo {
    height: 2.5rem;
    width: auto;
}

.navigation .button_like {
    background: var(--green);
    border-radius: 50px;
    /* padding: 12px 35px; */
    border: 1px solid var(--green);
    color: #fff;
    font-size: 1.4rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    grid-gap: 8px;
}

.navigation .button_like ion-icon {
    font-size: 2rem;
}

.navigation .button_like span {
    color: #fff;
}

.navigation .nav_icon {
    font-size: 2rem;
}

.navigation .ant-input-affix-wrapper {
    background: #f5f5f6;
    border: 1px solid #ecedf1;
    width: 40%;
    height: 4rem;
    border-radius: 50px;
}

.ant-drawer-body .uil-angle-right {
    font-size: 30px;
}

.sec-nav-group div.item {
    padding-right: 20px;
}


.sec-nav-group ::-webkit-scrollbar {
    width: 5px;
    height: 3px;
}

/* Track */

.sec-nav-group ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */

.sec-nav-group ::-webkit-scrollbar-thumb {
    background: #C1C1C1;
}

/* Handle on hover */

.sec-nav-group ::-webkit-scrollbar-thumb:hover {
    background: #C1C1C1;
}

.sec-nav-group.adjustMyNavBarSize {
    padding: 0px 2%;
    padding-top: 10px;
    margin: 0px;
}

.shop .sec-nav-group.adjustMyNavBarSize {
    display: block;
}

.navi a img {
    height: 2rem;
}

.sec-nav-group div.item a {
    color: #0a0a0a;
    font-size: 1.3rem;
    line-height: 1.5;
    /* font-family: "DM Sans", sans-serif !important; */
    font-weight: 500;
}

.sec-nav-group .links {
    display: -webkit-flex;
    display: flex;
    overflow-y: auto;
    white-space: nowrap;
    padding: 10px 0 15px;
    margin: 0 auto;
}

.sec-nav-group {
    width: 97%;
    width: 100%;
    border-bottom: 1px solid #ecedf1;
    padding-bottom: 0;
    padding-top: 5px;
    box-shadow: 0 2px 20px -20px rgb(0 0 0 / 8%);
    background: #fff;
}

.cartIcon {
    position: relative;
}

.cartIcon .cartCount {
    position: absolute;
    top: -10px;
    right: -15px;
    background: #C8182F;
    padding: 2px 8px;
    border-radius: 50%;
}

.cartIcon .cartCount p {
    margin: 0%;
    color: #fff;
    font-size: 1rem;
}

.navigation.shop {
    background: #fff !important;
    box-shadow: none;
    border-bottom: 1px solid #ecedf1;
    box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, .2);
}

.navigation .ant-input-affix-wrapper>input.ant-input {
    background: #f5f5f6;
}

.navigation.adjustMyNavBarSize {
    padding: 10px 2% !important;
    margin: 0 !important;
}

/*MENU TAB*/
.menu_custom_tab .ant-tabs-tab {
    background-color: green !important;
    color: white !important;
}

.menu_custom_tab .ant-tabs-tab-active {
    background-color: initial !important;
    color: initial !important;
}

/* snapshot */
.snapshot_design {
    border-style: solid;
    border-width: 2px;
    border-color: #111;
    border-radius: 7px;
    background-color: #fff;
    font-size: 13px;
    line-height: 20px;
    box-shadow: 3px 3px 0 0 #111;
    position: relative;
}

.snapshot_header {
    background: #093A3E;
    border-radius: 7px 7px 0 0;
}

.snapshot_header h4 {
    color: #fff;
    padding: 10px 15px;
    font-size: 16px;
}

.snapshot_box {
    display: -ms-flexbox;
    display: flex;
    /* min-height: 580px; */
    min-height: 100%;
    padding: 28px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f8f6f2;
}

.snapshot_design {
    background: #fff;
    position: relative;
    width: 100%;
    max-width: 368px;
    opacity: 1;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    border-radius: 7px;
}

.snapshot_footer {
    background: #EF3D3D;
    padding: 10px 15px;
}

.snapshot_design .customer {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
}

.snapshot_design .snapshot_footer h4 {
    font-size: 14px;
    /* color: #fff; */
    margin-bottom: 0%;
}

.snapshot_design .snapshot_footer h3 {
    /* color: #fff; */
    font-size: 14px;
    margin-bottom: 0%;
}

.konnect_props.green .snapshot_body p {
    color: #111111;
}

.konnect_props.green .snapshot_body h3 {
    color: #111111;
}

.snapshot_design .snapshot_footer span {
    font-size: 12px;
    padding: 1px 5px;
    color: #111;
    margin-bottom: 0%;
}

.snapshot_body {
    padding: 15px;
}

.snapshot_body .grid_2 h2 {
    font-size: 20px;
    margin-bottom: 2px;
}

.snapshot_body .grid_2 p {
    font-size: 14px;
    color: rgba(17, 17, 17, 0.75);
}

.snapshot_body h5 {
    font-size: 14px;
}

.other-nav {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    grid-gap: 15px;
}

.desktop {
    display: block;
}

.mobile {
    display: none;
}

.navigation .mobile .other-nav div {
    padding-right: 10px;
}

.navigation .mobile .other-nav div.lastNav {
    padding-right: 0px;
}

.search {
    background: #0000A4;
    padding: 8px 10px;
    display: block;
}

.ant-input-group-wrapper.ant-input-search {
    width: 100%;
}

.ant-input-group-wrapper.ant-input-search {
    border-radius: 50px;
    background: #F4F4F4;
    background: #fff;
}

.ant-drawer-header {
    border-bottom: 1px solid #aaa;
    padding-left: 10px;
    padding-right: 10px;
}

.ant-drawer-content-wrapper {
    width: 100% !important;
}

.ant-drawer-body ul li a p,
.ant-drawer-body ul li div p {
    color: #0a0a0a;
    line-height: 1.5;
    font-size: 13px;
    padding-top: 15px;
    margin: 0%;
    display: inline-block;
    font-weight: 500;
}

.ant-drawer-body ul li a,
.ant-drawer-body ul li div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ant-drawer-body ul li {
    border-width: 1px 0 0;
    border-bottom: 0.5px solid #E6E6E6;
    background-color: transparent;
    padding: 6px 10px;
    list-style: none;
}

.trans-code .ant-drawer-body ul li {
    border-width: 0 !important;
    border-bottom: 0 !important;
    background-color: transparent !important;
    padding: 6px 10px !important;
    list-style: disc !important;
}

.wishlist .ant-skeleton.ant-skeleton-element.ant-skeleton-active,
.wishlist .ant-skeleton.ant-skeleton-element.ant-skeleton-active .ant-skeleton-image {
    width: 100%;
    height: 15rem;
}

@media screen and (max-width: 420px) {
    .space_between {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
    }
}

@media screen and (max-width: 720px) {
    .bg_side_green {
        padding: 4px 15px;
    }

    .center-text svg {
        width: 15%;
        height: auto;
        margin-bottom: 20px;
        margin-top: 25%;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .mt-5 {
        margin-top: 15% !important;
    }

    .mt-3 {
        margin-top: 6% !important;
    }

    .navigation {
        padding-bottom: 15px;
    }

    .navigation.adjustPadding,
    .navigation {
        padding: 18px 1.5%;
    }

    .search {
        padding: 10px 10px;
    }

    .search .ant-input-group .ant-input,
    .search .ant-btn-icon-only {
        height: 4rem !important;
    }

    .search .ant-input-group-addon {
        background: #fff !important;
    }

    .ant-input-group-wrapper.ant-input-search .ant-input-group-addon {
        border-radius: 50px !important;
        height: inherit;
        background: #F4F4F4;
        border: 1px solid #222222;
        color: #222222;
    }

    .ant-btn-icon-only {
        height: 5rem !important;
        padding: 4px 15px;
        font-size: 16px;
        border-radius: 50px !important;
        vertical-align: -1px;
        width: auto;
        border: none;
        outline: none;
        box-shadow: none !important;
    }

    .ant-input-group .ant-input {
        border-radius: 50px;
        border: none;
        outline: none;
        box-shadow: none;
        height: 5rem;
    }

    .mobile-grid {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    /* .ant-drawer-body, .ant-drawer-content-wrapper,
    .ant-drawer, .ant-drawer .ant-drawer-content, .ant-drawer-wrapper-body {
        padding: 0px !important;
        margin: 0% !important;
        width: 100% !important;
    } */

    .grid_flex,
    .grid-flex-bias {
        display: block;
    }

    .ant-modal {
        width: 100%;
        height: max-content;
        display: flex;
        margin: 0%;
        padding: 0%;
        max-width: 100%;
        bottom: 0%;
        position: fixed;
        top: unset;
        overflow: hidden;
    }

    .ant-modal-body {
        padding: 10% 20px 5%;
        overflow: hidden;
    }

    .ant-select {
        width: 100%;
    }

    /* .ant-modal.airtimeModal {
        top: 40%;
    } */
    .cartIcon .cartCount {
        position: absolute;
        top: -20px;
        right: -10px;
        background: #C8182F;
        padding: 4px 8px;
        border-radius: 50%;
    }

    .mini_nav .div_flex {
        display: flex;
    }

    .mini_nav .div_flex ul {
        display: none;
    }

    .mini_nav .div_flex .wall_bal {
        display: flex;
        align-items: center;
        gap: '5px'
    }

    .mini_nav .div_flex p {
        text-align: center;
    }

    .shop .sec-nav-group.adjustMyNavBarSize {
        display: none;
    }

    .ant-radio-button-wrapper {
        height: auto;
    }

    /* .ant-drawer,  */
    .ant-drawer-content-wrapper {
        width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    input {
        font-size: 1.3rem !important;
    }

    .ant-tabs-tab {
        padding-bottom: 5px;
    }

    .ant-tabs-tab+.ant-tabs-tab {
        margin-left: 20px;
    }

    .center-text.wise9ja-success {
        padding-top: 10%;
    }
}

.ant-pagination-options {
    display: none;
}

.ant-modal-close {
    display: none;
}

.notificationNav {
    position: absolute;
    bottom: 0;
    background: #fff;
    width: 100%;
    padding-top: 15px;
    border-top: 1px solid #E8E9EB;
    padding-left: 15px;
}

.notificationNav ul li {
    display: inline-block;
    padding-right: 15px;
}

.notificationNav ul li a {
    color: #111;
}

@media screen and (min-width: 720px) {
    .ant-drawer-content-wrapper {
        width: 400px !important;
        overflow-x: hidden;
    }
}

.notificationBanner {
    background: #750046;
    border-radius: 7px;
    padding: 15px 15px;
}

.notificationUserName {
    font-size: 1.6rem;
    margin-bottom: 5px;
}

.notificationUserText {
    margin-bottom: 0%;
    font-size: 1.4rem;
    width: 80%;
}

.cancelNotification {
    background: #FAFAFA;
    border-radius: 50%;
    padding: 10px;
    border: 1px solid #DCDCDC;
    cursor: pointer;
}

.notificationBanner a {
    display: block;
    background: #fff;
    border-radius: 50px;
    padding: 6px 15px;
    border: 1px solid #fff;
    margin-bottom: 10px;
    width: max-content;
    color: #000;
}

.notificationBanner img.logo {
    height: 2rem;
    width: auto;
    margin-top: 20px;
}

.notificationBanner p,
.notificationBanner h4 {
    color: #fff;
}

.notificationBanner p {
    width: 90%;
}

.ant-drawer-header {
    padding: 15px;
}

.ant-drawer-body {
    padding: 0%;
}

.ant-drawer-body .notification-collapse-data {
    padding: 15px;
}

.notification-space-top {
    padding-top: 5%;
    margin-bottom: 20%;
}

.collapseData {
    margin-bottom: 5%
}

.orangeImage {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.drawerCancelButton {
    height: 20px;
}

.ant-drawer-close {
    display: none;
}


/* search box */
.desktop .ant-input-group-wrapper.ant-input-search .ant-input-group-addon {
    border-radius: 50px !important;
    height: inherit;
    background: #F4F4F4;
    border: 1px solid #222222;
    color: #222222;
}

.desktop .ant-btn-icon-only {
    height: 4.5rem !important;
    padding: 4px 15px;
    font-size: 16px;
    border-radius: 50px !important;
    vertical-align: -1px;
    width: auto;
    border: none;
    outline: none;
    box-shadow: none !important;
}

.desktop .ant-input-group .ant-input {
    border-radius: 50px;
    border: none;
    outline: none;
    box-shadow: none;
    height: 4.5rem;
}

.desktop .navigation .ant-input-group-wrapper.ant-input-search input.ant-input {
    background: #f5f5f6;
}


.desktop .navigation .ant-input-group-wrapper {
    background: #f5f5f6;
    border: 1px solid #ecedf1;
    width: 40%;
    /* height: 4rem; */
    border-radius: 50px;
}

.desktop .navigation .ant-btn.ant-btn-default.ant-btn-icon-only {
    background: #f5f5f6;
    border: 1px solid #f5f5f6;
}

.desktop .navigation .ant-input-group-wrapper.ant-input-search .ant-input-group-addon {
    border: 1px solid #f5f5f6;
    color: #222222;
}

.ant-modal-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}



.grid-6 {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 5px;
}

.search-page {
    padding-top: 10%;
}

.owl-carousel .owl-stage {
    padding-left: 0px !important;
    padding-right: 0% !important;
}

.search-page-data {
    padding-bottom: 5%;
}

@media (max-width: 720px) {
    .ant-modal-centered .ant-modal {
        top: 50% !important;
        transform: translateY(-50%) !important;
    }

    .grid-6 {
        grid-template-columns: repeat(2, 1fr);
    }

    .props,
    .banner {
        margin-top: 10%;
    }

    .mobile-list-flex li {
        display: flex;
        justify-content: space-between;
    }

    .display-grid.dashboard-grid-main-message {
        grid-gap: 0px;
    }

    .new_flex,
    .top_header .flex-2 {
        display: block;
    }
}

.desktop_menu {
    display: flex !important;
}

.mobile_menu {
    display: none !important;
}

@media (max-width: 1024px) {
    .desktop_menu {
        display: none !important;
    }

    .mobile_menu {
        display: flex !important;
    }
}

.center-text.wise9ja-success p {
    font-size: 1.4rem;
    display: block;
}

.center-text.wise9ja-success a {
    font-size: 1.3rem;
    display: block;
}

/* 
.nav-display .ant-input-group {
    border-radius: 50px;
    height: 5rem;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    grid-gap: 0px;
}

.nav-display .ant-input-group .ant-input {
    height: 5rem;
    border: none;
    border-radius: 50px;
    border: none;
    border-top: 1px solid #262626;
    border-bottom: 1px solid #262626;
    border-right: none;
}

.nav-display input:focus{
    outline: #262626 !important;
}

.nav-display .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    border-radius: 50px;
    height: 13rem;
    border: none;
    background: transparent;
    width: 40px;
}

.nav-display .ant-input-search .ant-input-search-button svg {
    font-size: 20px;
}

.nav-display .ant-input-wrapper.ant-input-group {
    border: 1px solid #262626;
    border-radius: 50px;
    z-index: 10000;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child {
    background: #fff;
}

.nav-display .ant-input-search > .ant-input-group > .ant-input-group-addon {
    display: block;
    width: 20%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
} */
/* search box */
.ant-input-group-wrapper.ant-input-search .ant-input-group-addon {
    border-radius: 50px !important;
    height: inherit;
    background: #F4F4F4;
    border: 1px solid #222222;
    color: #222222;
}

.ant-btn-icon-only {
    height: 4.5rem !important;
    padding: 4px 15px;
    font-size: 16px;
    border-radius: 50px !important;
    vertical-align: -1px;
    width: auto;
    border: none;
    outline: none;
    box-shadow: none !important;
}

.ant-input-group .ant-input {
    border-radius: 50px;
    border: none;
    outline: none;
    box-shadow: none;
    height: 4.5rem;
}

.navigation .ant-input-group-wrapper.ant-input-search input.ant-input {
    background: #f5f5f6;
}


.navigation .ant-input-group-wrapper {
    background: #f5f5f6;
    border: 1px solid #ecedf1;
    width: 40%;
    /* height: 4rem; */
    border-radius: 50px;
}

.navigation .ant-btn.ant-btn-default.ant-btn-icon-only {
    background: #f5f5f6;
    border: 1px solid #f5f5f6;
}

.navigation .ant-input-group-wrapper.ant-input-search .ant-input-group-addon {
    border: 1px solid #f5f5f6;
    color: #222222;
}

.loyalty_card {
    width: 30%;
}

@media (max-width: 1200px) {
    .loyalty_card {
        width: 100%;
    }
}


@media (min-width: 720px) and (max-width: 1200px) {
    .desktop {
        display: none;
    }

    .desktop.show-all {
        display: block !important;
    }

    .mobile.show-all {
        display: block !important;
    }

    .ant-drawer-wrapper-body .ant-drawer-header .mobile-grid {
        display: flex;
        justify-content: space-between;
    }
}

.fund-wallet-div .ant-radio-group-large .ant-radio-button-wrapper {
    padding: 2px 35px;
    height: unset;
}

.fund-wallet-div .ant-radio-group-large .ant-radio-button-wrapper span {
    font-size: 1.4rem;
}

.fund-wallet-div .ant-radio-group-large .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    background: #008060;
}

.notifData {
    display: flex;
    height: 100%;
}

.notifData .emptyData {
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.notifData .emptyData p {
    font-size: 1.4rem;
    line-height: 1.8;
}

.nav-notif-grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.extra-info-modal ul.mobile-list-flex li {
    display: flex;
    justify-content: space-between;
    line-height: 1.6;
    margin-bottom: 20px;
}

.extra-info-modal ul.mobile-list-flex li span.adjust {
    text-align: right;
}

.collapsible {
    cursor: pointer;
    display: inline-block;
}

.collapsible-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 10px 0;
}

.collapsible-content li {
    display: block;
    padding: 8px 16px;
}

.collapsible-content li:hover {
    background-color: #f1f1f1;
}

/* Show the collapsible content when the state is true */
ul li .collapsible-content {
    display: block;
}


.checkoutDrawer_wrapper.ant-drawer-content {
    border-radius: 0 0 !important;
}

.checkoutDrawer_wrapper .ant-drawer-header {
    border: 0 !important;
    border-bottom: 0 !important;
    display: none;
}

.checkoutDrawer_wrapper .ant-drawer-content-wrapper .ant-drawer-header {
    border: 0 !important;
    border-radius: 0 0 !important;
}


.slider {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 100vw;
}

.slider-track {
    display: flex;
    gap: 120px;
    animation: scroll 20s linear infinite;
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-50%);
    }
}