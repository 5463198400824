.landing_hero h3 {
    width: 80%;
}

.grid_flex {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
}

.sideImg {
    height: 100% !important;
    width: fit-content !important;
}

.grid_flex img {
    height: 100%;
    width: 100%;
}

.grid_flex img.land {
    width: 70%;
}

.landing_hero {
    height: 500px;
}

.landing_hero.val {
    background: url("../images/val.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.grid_card {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.card_info {
    width: 70%;
    border-radius: 5px;
    padding: 20px;
    background: #fff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    color: black;
    display: flex;
    flex-direction: column;
}

.list_container {
    margin-left: 20px;
}

p,
b {
    font-size: 2rem;
    margin: 0;
}

.card_info i {
    font-size: 1.5rem;
    margin-top: 5px;
}

.card_info h4 {
    font-size: 3rem;
}

.joinus_button {
    margin: 10px 0px;
    width: 15rem;
    text-align: center;
    font-size: 1.7rem;
    background: rgba(0, 0, 0, 0.9);
    color: #f1fef4;
    padding: 10px;
    border-radius: 10px;
}

.joinus_button:hover {
    color: #f1fef4;
    text-decoration: none;
}

.divider1 {
    width: 50%;
}

.divider2 {
    width: 50%;
    font-size: 2.5rem;
}

.sub_title {
    margin: 10px 0px 5px 0px
}

.divider {
    margin-top: 20px;
    border: none !important;
}

.card_padding {
    padding: 50px;
}

.card_padding_join {
    padding: 2%;
}

.card_info_join {
    width: 100%;
    border-radius: 5px;
    padding: 20px;
    background: #fff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    color: black;
    display: flex;
    flex-direction: column;
}

.card_info_join i {
    font-size: 1.5rem;
    margin-top: 5px;
}

.card_info_join h4 {
    font-size: 3rem;
}

label {
    font-size: 14px !important;
}

.card_info_title {
    font-size: 4.5rem !important;
    text-align: center;
}

.card_info_no {
    font-size: 6rem !important;
    text-align: center;
}

.special-label {
    display: none;
}

.auth-group input,
.auth-group select,
.auth-group .ant-select-single {
    border: 1px solid #d9d9d9 !important;
    border-radius: 8px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    /* font-size: 12px !important; */
}

.ant-form-item {
    margin-bottom: 12px !important;
}

@media screen and (max-width: 720px) {
    .landing_hero h3 {
        width: 100%;
    }

    .sideImg {
        height: fit-content !important;
        width: 100% !important;
    }

    .card_padding {
        padding: 25px;
    }

    p,
    b {
        font-size: 1.5rem;
        margin: 0;
    }

    li {
        font-size: 1.5rem;
        margin-top: 5px;
    }

    .divider1 {
        width: 50%;
    }

    .divider2 {
        width: 50%;
        font-size: 2rem;
    }

    .card_info h4 {
        margin: auto;
        font-size: 2rem;
    }

    .card_info_join h4 {
        margin: auto;
        font-size: 2rem;
    }

    .card_info_title {
        font-size: 2.5rem !important;
        text-align: center;
    }

    .card_info_no {
        font-size: 3rem !important;
        text-align: center;
    }

    .card_info {
        width: 100%;
        border-radius: 5px;
        padding: 20px;
        background: #fff;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
        color: black;
        display: flex;
    }

    .landing_hero {
        height: 100%;
        padding: 50px 0;
    }

    .landing_hero.val {
        height: 550px;
        padding: 50px 0;
    }

    .grid_flex {
        display: flex !important;
        flex-direction: column;
        grid-gap: 20px;
    }

    .grid_flex img.land {
        width: 100%;
    }
}